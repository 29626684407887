<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CreativeForm :is-sending="isSending" :initial-values="initialValues" @formSubmit="onSubmit" />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CREATE_CREATIVE } from '@/store/action-types/creatives';
import CreativeForm from './CreativeForm';

export default {
  name: 'CreativeCreate',
  components: {
    CreativeForm,
  },
  data() {
    return {
      initialValues: {
        name: '',
        campaignId: '',
        type: '',
        active: true,
        moderated: false,
        approved: false,
        linkType: 'MP',
        cpm: 0,
        dateEnd: null,
        dateStart: null,
        clickUrl: '',
        media: [],
        sportFeedMatchId: null,
        sportFeedActive: false,
      },
    };
  },
  computed: {
    ...mapState('creatives', ['isSending']),
  },
  methods: {
    ...mapActions('creatives', [CREATE_CREATIVE]),
    onSubmit(formData) {
      this.CREATE_CREATIVE({ id: formData.campaignId, formData })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.new_block_added'),
          });
          this.$router.push({ name: 'creatives' });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.adding_record_error'),
          });
        });
    },
  },
};
</script>
